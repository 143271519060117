import gas_luce from "../../images/hub-icons/ico-hub-gas-e-luce.png";
import gas from "../../images/hub-icons/ico-hub-gas.png";
import luce from "../../images/hub-icons/ico-hub-luce.png";
import green from "../../images/hub-icons/ico-hub-green.png";
import domuscheck from "../../images/hub-icons/ico-hub-domus-check.png";
import sdd from "../../images/hub-icons/ico-hub-sdd.png";
import area_clienti from "../../images/hub-icons/ico-hub-user.png";
import app from "../../images/hub-icons/ico-hub-app-mobile.png";
import prezzo_bloccato from "../../images/hub-icons/ico-hub-prezzo-bloccato.png";
import prezzo_variabile from "../../images/hub-icons/ico-hub-prezzo-variabile-new.png";
import assic_zerop from "../../images/hub-icons/ico-hub-shield.png";
import assicurazione_casa from "../../images/hub-icons/ico-hub-umbrella.png";
import voucher from "../../images/hub-icons/ico-hub-voucher.png";
import nest_thermostat from "../../images/hub-icons/ico-hub-nest-thermostat.png";
import nest_cam from "../../images/hub-icons/ico-hub-nest-cam.png";
import nest_protect from "../../images/hub-icons/ico-hub-nest-thermostat.png";
import manutenzione_caldaia from "../../images/hub-icons/ico-hub-manutenzione-caldaia.png";
import caldaia from "../../images/hub-icons/ico-hub-caldaia.png";
import fotovoltaico from "../../images/hub-icons/ico-hub-fotovoltaico.png";
import smart_plug from "../../images/hub-icons/ico-hub-smart-plug.png";
import bolletta_online from "../../images/hub-icons/ico-hub-bolletta-online.png";

const HUBCaratteristicheProdottoDLL = {
  gas_luce: {
    icona: gas_luce,
    descr: "Gas e/o luce",
  },
  gas: {
    icona: gas,
    descr: "Solo gas",
  },
  luce: {
    icona: luce,
    descr: "Solo luce",
  },
  green: {
    icona: green,
    descr: "Energia 100% rinnovabile",
  },
  domuscheck: {
    icona: domuscheck,
    descr: "Domuscheck",
  },
  sdd: {
    icona: sdd,
    descr: "Addebito diretto conto corrente",
  },
  area_clienti: {
    icona: area_clienti,
    descr: "Spazio Clienti",
  },
  app: {
    icona: app,
    descr: "App mobile",
  },
  prezzo_bloccato: {
    icona: prezzo_bloccato,
    descr: "Prezzo Bloccato 24 mesi",
  },
  prezzo_bloccato12: {
    icona: prezzo_bloccato,
    descr: "Prezzo Bloccato 12 mesi",
  },
  prezzo_bloccato1224: {
    icona: prezzo_bloccato,
    descr: "Prezzo Bloccato 12 o 24 mesi",
  },
  prezzo_variabile: {
    icona: prezzo_variabile,
    descr: "Prezzo variabile",
  },
  assic_zerop: {
    icona: assic_zerop,
    descr: "Assicurazione omaggio 12 mesi",
  },
  assicurazione_casa: {
    icona: assicurazione_casa,
    descr: "Assicurazione per la casa",
  },
  voucher: {
    icona: voucher,
    descr: "Buono acquisto da 20€",
  },
  nest_thermostat: {
    icona: nest_thermostat,
    descr: "Nest E Thermostat a 9 €/mese",
  },
  nest_cam: {
    icona: nest_cam,
    descr: "Nes Cam a 7 €/mese",
  },
  nest_protect: {
    icona: nest_protect,
    descr: "Nest Protect a 5 €/mese",
  },
  manutenzione_caldaia: {
    icona: manutenzione_caldaia,
    descr: "Manutenzione Caldaia",
  },
  caldaia: {
    icona: caldaia,
    descr: "Manutenzione caldaia",
  },
  fotovoltaico: {
    icona: fotovoltaico,
    descr: "Fotovoltaico",
  },
  smart_plug: {
    icona: smart_plug,
    descr: "Smart plug",
  },
  bolletta_online: {
    icona: bolletta_online,
    descr: "Bolletta online",
  },
};

export default HUBCaratteristicheProdottoDLL;
